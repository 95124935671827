:root {
  --back-color: #fff;
  --main-color: #57bf9e;
  --cust-shadow: 0 0 10px gray;
  --test: var(--main-color);
}

@media (prefers-color-scheme: dark) {
  :root {
    --back-color: #000;
    --main-color: #fff;
    --cust-shadow: none;
    --test: var(--back-color);
  }
}

html {
  color: var(--main-color);
}

body {
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: var(--test);
}

video {
  width: 100%;
}

.App {
  max-width: 1200px;
  margin: 0 auto;
  background-color: var(--back-color);
}

nav {
  text-align: center;
}

nav a {
  text-decoration: none;
  margin: 10px;
  color: var(--main-color);
}

main {
  min-height: 70vh;
  margin-top: 10px;
}

footer {
  padding-bottom: 25px;
  text-align: center;
}

hr {
  width: 85%;
}

h2 {
  color: var(--main-color);
  margin: 15px;
  font-family: "Lobster", cursive;
}

img {
  width: 100%;
  height: auto;
  margin-top: 10px;
  box-shadow: var(--cust-shadow);
}

iframe {
  box-shadow: var(--cust-shadow);
}

.phone {
  padding: 10px;
}

.phone a {
  text-decoration: none;
  color: var(--main-color);
  font-size: 1.5em;
}

.social-icon {
  margin: 0 5px;
}

.sub-title {
  font-size: 0.8em;
  font-style: italic;
}

.logo {
  width: 70%;
  height: auto;
  margin: 15px auto 0;
  box-shadow: none;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.home {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact-main {
  width: 90%;
  margin: 0 auto;
}

.person-card {
  width: 90%;
  margin: 0 auto;
}

@media (min-width: 400px) {
  .basketball {
    width: 40%;
    max-width: 95px;
    float: left;
    margin: 5px 15px 15px 0;
  }
  .menu {
    visibility: hidden;
  }
  .navigation {
    visibility: visible;
  }
}

.xpand {
  max-height: 200px !important;
}

@media (max-width: 401px) {
  .menu {
    visibility: visible;
  }

  .navigation {
    background-color: var(--back-color);
    overflow: hidden;
    padding: 0 20px;
    text-align: left;
    position: fixed;
    top: 30px;
    left: 30px;
    border-radius: 6px;
    box-shadow: var(--cust-shadow);
    max-height: 0;
    transition: max-height 0.3s ease-in;
  }
  .navigation a {
    display: block;
  }
}

.story {
  width: 90%;
  margin: 0 auto;
}

.contact-info {
  padding: 15px;
}

.contact-info div {
  padding-top: 5px;
}

.project {
  color: var(--main-color);
  padding: 15px;
}

.home-contact-info {
  text-align: left;
  padding-top: 15px;
}

.copyright {
  font-size: 0.8em;
  padding-top: 10px;
}

.menu {
  position: absolute;
  top: 15px;
  left: 15px;
  display: inline-block;
  cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: var(--main-color);
  margin: 6px 0;
  transition: 0.4s;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}
